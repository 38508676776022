<template>
  <b-row>
    <b-col sm="12">
      <h3>Other Contacts</h3>
      <p class="text-muted">List dependants, business partners, other relevant contacts here.</p>
      <b-row>
        <b-col sm="12">
          <b-table hover :small="true" :items="contacts" :fields="otherContactFields" @row-clicked="editContact" v-if="contacts.length > 0">
            <template v-slot:cell(delete)="data">
              <div class="text-right">
                <b-button size="sm" variant="ghost-danger" @click.stop="deleteContact(data.item)"><i class="fa fa-remove"></i></b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-button size="sm" variant="outline-success" @click="addContact()">
        <i class="fa fa-plus"></i>&nbsp;Add Contact
      </b-button>
      <contact-modal @save="saveContact" @delete="deleteContact"></contact-modal>
    </b-col>
  </b-row>
</template>
<script>
import Dollars from '@/components/text/Dollars'
import ContactModal from '@/components/modals/ContactModal'
import moment from 'moment'

export default {
  props: [ 'clientId', 'value' ],
  components: {
    ContactModal,
    Dollars
  },
  computed: {
    contacts () {
      return this.value.contacts.filter(o => o.contactTypeId > 2)
    }
  },
  data() {
    return {
      otherContacts: [],
      otherContactFields: [
        { key: 'name', label: 'Name', formatter: (value, key, item) => `${item.firstName} ${item.lastName} (${item.age})` },
        { key: 'type.name', label: 'Type' },
        { key: 'birthday', label: 'Birthday', formatter: (value, key, item) => item.birthday ? `${moment(item.birthday).format('MM/DD/YYYY')}` : '' },
        { key: 'delete', label: '', class: 'delete' }
      ],
    }
  },
  methods: {
    addContact () {
      this.$bus.$emit('modal:editContact', {}, 2)
    },
    editContact (contact) {
      this.$bus.$emit('modal:editContact', { ...contact }, 2)
    },
    saveContact (value) {
      let token = this.$route.params.token
      value.clientId = this.clientId
      if (value.id) {
        this.$api.clients.intake.updateContact({ token: token, id: value.id }, value)
          .then((response) => {
            this.editing = null
            this.$snotify.success('Contact Saved Successfully')
            this.$emit('update', value)
          })
          .catch(() => {
            this.$snotify.error('There was a problem saving the contact.')
          })
      } else {
        this.$api.clients.intake.createContact({ token: token }, value)
          .then((response) => {
            this.$snotify.success('Contact Added Successfully')
            this.$emit('update', value)
          })
          .catch(() => {
            this.$snotify.error('There was a problem saving the contact.')
          })
      }
    },
    deleteContact (value) {
      let token = this.$route.params.token
      if (value.id > 0) {
        this.$api.clients.intake.deleteContact({ token: token, id: value.id })
          .then((response) => {
            this.$snotify.success('Contact Deleted Successfully')
            this.$emit('update')
          })
          .catch(() => {
            this.$snotify.error('There was a problem deleting the contact.')
          })
      }
    },
  }
}
</script>
