<template>
  <b-row>
    <b-col sm="12">
      <h3>Comments</h3>
      <p class="text-muted">Provide any additional comments to us here.  When finished, scroll to the bottom and click "Complete Registration"</p>

      <b-row class="form-group">
        <b-col sm="12">
          <b-textarea :rows="6" :no-resize="true" v-model="value.comments.$model" :state="state(value.comments)" @input="touch(value.comments)"></b-textarea>
          <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" class="mt-3 text-right">
          <b-button variant="success" :disabled="!value.comments.$dirty" @click="save()">Save Comments</b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: [ 'value' ],
  methods: {
    save () {
      this.value.comments.$touch()
      if (this.value.comments.$invalid) {
        this.$snotify.error('Validation Error: Please check the form for errors')
      } else {
        this.$emit('update', this.value)
      }
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    }
  }
}
</script>
