<template>
  <b-row>
    <b-col sm="12">
      <h3>Mortgages</h3>
      <p class="text-muted">List mortgages on any homes or properties you own.</p>
      <b-table v-if="value.mortgages.length > 0" :small="true" hover :items="value.mortgages" :fields="fields" @row-clicked="edit" foot-clone>
        <template v-slot:cell(balance)="data">
          <dollars :value="data.value"></dollars>
        </template>
        <template v-slot:cell(minimumPayment)="data">
          <dollars :value="data.value" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:cell(actualPayment)="data">
          <dollars :value="data.value" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:cell(rate)="data">
          <percent :value="data.value"></percent>
        </template>
        <template v-slot:cell(delete)="data">
          <div class="text-right">
            <b-button size="sm" variant="ghost-danger" @click.stop="remove(data.item)"><i class="fa fa-remove"></i></b-button>
          </div>
        </template>
        <template v-slot:foot(name)><span></span></template>
        <template v-slot:foot(type)><span></span></template>
        <template v-slot:foot(balance)>
          <dollars :value="totalBalance"></dollars>
        </template>
        <template v-slot:foot(minimumPayment)>
          <dollars :value="totalMinimumPayment" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:foot(actualPayment)>
          <dollars :value="totalActualPayment" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:foot(rate)><span></span></template>
        <template v-slot:foot(years)><span></span></template>
      </b-table>
      <b-button size="sm" variant="outline-success" @click="add()">
        <i class="fa fa-plus"></i>&nbsp;Add Mortgage
      </b-button>
      <mortgage-modal v-on:save="save"></mortgage-modal>
    </b-col>
  </b-row>
</template>
<script>
import MortgageModal from '@/components/modals/MortgageModal'
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'

export default {
  props: [ 'clientId', 'value' ],
  components: {
    MortgageModal,
    Dollars,
    Percent
  },
  computed: {
    totalBalance: function () {
      let total = 0.0
      this.value.mortgages.forEach(function(item) { total += parseFloat(item.balance) })
      return total
    },
    totalMinimumPayment: function () {
      let total = 0.0
      this.value.mortgages.forEach(function(item) { total += parseFloat(item.minimumPayment) })
      return total
    },
    totalActualPayment: function () {
      let total = 0.0
      this.value.mortgages.forEach(function(item) { total += parseFloat(item.actualPayment) })
      return total
    }
  },
  data () {
    return {
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'type', label: 'Type', formatter: (value, key, item) => item.type.name },
        { key: 'balance', class: "numeric" },
        { key: 'minimumPayment', class: 'numeric' },
        { key: 'actualPayment', class: 'numeric' },
        { key: 'rate', class: "numeric" },
        { key: 'years', class: "numeric" },
        { key: 'delete', label: '', class: 'delete' }
      ],
      items: [],
      editing: null
    }
  },
  methods: {
    add () {
      this.$bus.$emit('modal:editMortgage', null, this.value.assets)
    },
    edit (item) {
      let index = this.value.mortgages.indexOf(item)
      this.editing = index
      this.$bus.$emit('modal:editMortgage', item, this.value.assets)
    },
    remove (item) {
      let token = this.$route.params.token
      this.$api.clients.intake.deleteMortgage({ token: token, id: item.id })
        .then(() => {
          this.$emit('update')
          this.$snotify.success('Mortgage Deleted Successfully')
        })
        .catch((err) => {
          this.$snotify.error('There was a problem removing the policy.')
        })
    },
    save (mortgage, asset) {
      let token = this.$route.params.token
      asset.clientId = this.value.id
      if (mortgage.assetId === null) {
        this.saveMortgage(mortgage)
      } else if (asset.id) {
        this.$api.clients.intake.updateAsset({ token: token, id: asset.id }, asset)
          .then((response) => {
            this.saveMortgage(mortgage)
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the asset.')
          })
      } else {
        this.$api.clients.intake.createAsset({ token: token }, asset)
          .then((response) => {
            this.value.assets.push(response.data)
            mortgage.assetId = response.data.id
            this.saveMortgage(mortgage)
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the asset.')
          })
      }
    },
    saveMortgage (value) {
      let token = this.$route.params.token
      value.clientId = this.value.id
      if (value.id) {
        this.$api.clients.intake.updateMortgage({ token: token, id: value.id }, value)
          .then((response) => {
            this.$emit('update', value)
            this.$snotify.success('Mortgage Saved Successfully')
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the Mortgage.')
          })
      } else {
        this.$api.clients.intake.createMortgage({ token: token }, value)
          .then((response) => {
            this.$emit('update', value)
            this.$snotify.success('Mortgage Added Successfully')
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the Mortgage.')
          })
      }
    }
  }
}
</script>
