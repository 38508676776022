<template>
  <b-row>
    <b-col sm="12">
      <h3>Spouse or Significant Other</h3>
      <!-- Name -->
      <b-row class="form-group">
        <b-col sm="12">
            <label>Name</label>
            <b-row>
              <b-col md="6">
                <b-form-input type="text" placeholder="First Name" v-model.trim="value.firstName.$model" @input="touch(value.firstName)"></b-form-input>
              </b-col>
              <b-col md="6">
                <b-form-input type="text" placeholder="Last Name" v-model.trim="value.lastName.$model" @input="touch(value.lastName)"></b-form-input>
              </b-col>
            </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <label for="birthday">Birthday</label>
          <birthday :value="value.$model.birthday" @update="birthday" @input="touch(value.birthday)" :state="state(value.birthday)"></birthday>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <label>Contact Information</label>
          <b-row>
            <b-col sm="6">
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text><i class="icon-home"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="Home Phone" v-model.trim="value.$model.homePhone.number" @input="touch(value.homePhone.number)" />
              </b-input-group>
            </b-col>
            <b-col sm="6">
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text><i class="icon-screen-smartphone"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="Cell Phone" v-model.trim="value.$model.mobilePhone.number" @input="touch(value.mobilePhone.number)" />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-input-group class="mb-3">
            <b-input-group-prepend>
              <b-input-group-text>@</b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text" class="form-control" placeholder="Email" autocomplete="email" v-model.trim="value.$model.emailAddress.address" :state="state(value.emailAddress.address)" @input="touch(value.emailAddress.address)" />
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Income Information -->
      <h3>Income Information</h3>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Monthly Take Home" label-for="monthly_income" description="Enter the amount you receive after taxes">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>$</b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" class="form-control" placeholder="e.g 1000.00" v-model.trim="value.$model.income.monthly" @input="touch(value.income.monthly)" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" class="mt-3 text-right">
          <b-button variant="success" :disabled="!value.$anyDirty" @click="save()">Save Secondary Contact</b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import LifeInsuranceModal from '@/components/modals/LifeInsuranceModal'
import Birthday from '@/components/inputs/Birthday'
import Dollars from '@/components/text/Dollars'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  props: [ 'value' ],
  components: {
    LifeInsuranceModal,
    Birthday,
    Dollars,
    flatPickr
  },
  data() {
    return {
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      existingPolicies: {
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'type.name', label: 'Type' },
          { key: 'faceValue', class: 'numeric' },
          { key: 'annualPremium', class: 'numeric' },
          { key: 'cashValue', class: 'numeric' },
          { key: 'loanAmount', class: 'numeric' },
          { key: 'delete', label: '', class: 'delete' }
        ],
        editing: null
      },
      contact: {
        firstName: null,
        lastName: null,
        emailAddress: {
          address: null
        },
        existingPolicies: []
      }
    }
  },
  methods: {
    birthday (value) {
      this.value.$model.birthday = value
    },
    save () {
      this.value.$touch()
      if (this.value.$invalid) {
        this.$snotify.error('Validation Error: Please check the form for errors')
      } else {
        this.$emit('update', this.value)
      }
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    }
  },
  watch: {
    contact: {
      handler: function (value) {
        this.$emit('update', value)
      },
      deep: true
    }
  }
}
</script>
