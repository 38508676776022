<template>
  <b-row>
    <b-col sm="12">
      <h3>Head of Household Information</h3>
      <!-- Name -->
      <b-row class="form-group">
        <b-col sm="12">
            <label for="first_name">Name</label>
            <b-row>
              <b-col md="6">
                <b-form-input type="text" placeholder="First Name" v-model.trim="value.firstName.$model" :state="state(value.firstName)" @input="touch(value.firstName)"></b-form-input>
                <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
              </b-col>
              <b-col md="6">
                <b-form-input type="text" placeholder="Last Name" v-model.trim="value.lastName.$model" :state="state(value.lastName)" @input="touch(value.lastName)"></b-form-input>
                <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
              </b-col>
            </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <label for="birthday">Birthday</label>
          <birthday :value="value.$model.birthday" @update="birthday" @input="touch(value.birthday)" :state="state(value.birthday)"></birthday>
          <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
        </b-col>
      </b-row>
      <!-- Address -->
      <b-row class="form-group">
        <b-col sm="12">
          <label>Address</label>
          <b-row>
            <b-col md="6" class="mb-2">
              <b-form-select id="country" size="1"
                :plain="true"
                :options="countryOptions"
                v-model="value.$model.address.countryId"  @input="touch(value.address.countryId)">
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="12">
              <b-form-input type="text" class="form-control" placeholder="Street" v-model.trim="value.address.street.$model" @input="touch(value.address.street)" :state="state(value.address.street)" />
              <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-input type="text" class="form-control" placeholder="City" v-model.trim="value.$model.address.city" @input="touch(value.address.city)" />
            </b-col>
            <b-col md="3">
              <b-form-select id="province" size="1"
                :plain="true"
                :options="provinceOptions"
                v-model="value.$model.address.provinceId"  @input="touch(value.address.provinceId)">
              </b-form-select>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" class="form-control" placeholder="Postal Code" v-model.trim="value.$model.address.postalCode" @input="touch(value.address.postalCode)" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

        <!-- Contact Information -->
      <b-row>
        <b-col sm="12">
          <label>Contact Information</label>
          <b-row>
            <b-col sm="6">
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text><i class="icon-home"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="Home Phone" v-model.trim="value.$model.homePhone.number" @input="touch(value.homePhone.number)" />
              </b-input-group>
            </b-col>
            <b-col sm="6">
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text><i class="icon-screen-smartphone"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="Cell Phone" v-model.trim="value.$model.mobilePhone.number" @input="touch(value.mobilePhone.number)" />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Email/Birthday -->
      <b-row>
        <b-col sm="6">
          <b-input-group class="mb-3">
            <b-input-group-prepend>
              <b-input-group-text>@</b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text" class="form-control" placeholder="Email" autocomplete="email" v-model.trim="value.$model.emailAddress.address" :state="state(value.emailAddress.address)" @input="touch(value.emailAddress.address)" />
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Income Information -->
      <h3>Income Information</h3>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Monthly Take Home" label-for="monthly_income" description="Enter the amount you receive after taxes">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>$</b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" class="form-control" placeholder="e.g 1000.00" v-model.trim="value.$model.income.monthly" @input="touch(value.income.monthly)" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Additional Monthly Income" label-for="additional_monthly_income" description="Enter any additional monthly income">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>$</b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" class="form-control" placeholder="e.g 1000.00" v-model.trim="value.$model.income.additionalMonthly" @input="touch(value.income.additionalMonthly)" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" class="mt-3 text-right">
          <b-button variant="success" @click="save()">Save Primary Contact</b-button>
          {{  $v.$invalid }}
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import LifeInsuranceModal from '@/components/modals/LifeInsuranceModal'
import Birthday from '@/components/inputs/Birthday'
import Dollars from '@/components/text/Dollars'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { mapGetters } from 'vuex'
import { required } from "vuelidate/lib/validators"

export default {
  props: [ 'clientId', 'value' ],
  components: {
    LifeInsuranceModal,
    Birthday,
    Dollars,
    flatPickr
  },
  computed: {
    countryOptions () {
      return [{ value: null, text: 'Country', disabled: true }].concat(this.countries.map(country => ({ value: country.id, text: country.name })))
    },
    provinceOptions () {
      return [{ value: null, text: 'State/Province', disabled: true }].concat(this.provinces
        .filter(province => province.countryId == this.value.$model.address.countryId)
        .map(province => ({ value: province.id, text: province.name })))
    },
    ...mapGetters({
      countries: 'global/countries',
      provinces: 'global/provinces'
    })
  },
  data() {
    return {
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      existingPolicies: {
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'type.name', label: 'Type' },
          { key: 'faceValue', class: 'numeric' },
          { key: 'annualPremium', class: 'numeric' },
          { key: 'cashValue', class: 'numeric' },
          { key: 'loanAmount', class: 'numeric' },
          { key: 'delete', label: '', class: 'delete' }
        ],
        types: {},
        editing: null
      },
    }
  },
  methods: {
    birthday (value) {
      this.value.$model.birthday = value
    },
    save () {

      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.$emit('update', this.value)
      }
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
  },
  validations: {
    value: {
      $model: {
        address: {
          street: {
            required
          }
        }
      }
    }
  }
}
</script>

<style>
  .flatpickr-input[readonly] {
    background: inherit;
  }
  .input-group.is-invalid > .invalid-feedback {
    display: block;
  }
  .input-group.is-invalid > input.form-control {
    border-color: #f86c6b;
  }
  .input-group.is-valid > input.form-control {
    border-color: #4dbd74;
  }
</style>
