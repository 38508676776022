<template>
  <b-row>
    <b-col sm="12">
      <h3>Assets</h3>
      <p class="text-muted">List your savings, stocks, property, mutual funds, retirement accounts, qualified plans and any other assets here.</p>
      <!-- Assets -->
      <b-table v-if="value.assets.length > 0" hover :small="true" :items="value.assets" :fields="fields" @row-clicked="edit" foot-clone>
        <template v-slot:cell(balance)="data">
          <dollars :value="data.value"></dollars>
        </template>
        <template v-slot:cell(monthlyContribution)="data">
          <dollars :value="data.value" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:cell(delete)="data">
          <div class="text-right">
            <b-button size="sm" variant="ghost-danger" @click.stop="remove(data.item)"><i class="fa fa-remove"></i></b-button>
          </div>
        </template>
        <template v-slot:foot(name)><span></span></template>
        <template v-slot:foot(type)><span></span></template>
        <template v-slot:foot(balance)>
          <dollars :value="totalBalance"></dollars>
        </template>
        <template v-slot:foot(monthlyContribution)>
          <dollars :value="totalMonthlyContribution" :postfix="'mo'"></dollars>
        </template>
      </b-table>

      <!-- Add Asset -->
      <b-button size="sm" variant="outline-success" @click="add()">
        <i class="fa fa-plus"></i>&nbsp;Add Asset
      </b-button>
      <asset-modal @save="save"></asset-modal>
    </b-col>
  </b-row>
</template>
<script>
import AssetModal from '@/components/modals/AssetModal'
import Dollars from '@/components/text/Dollars'
export default {
  props: [ 'clientId', 'value' ],
  components: { AssetModal, Dollars },
  data () {
    return {
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'type', label: 'Type', formatter: (value, key, item) => item.type.name },
        { key: 'balance', label: 'Total', class: 'numeric' },
        { key: 'monthlyContribution', class: 'numeric' },
        { key: 'delete', label: '', class: 'delete' }
      ],
      types: [
        { value: null, text: 'Select Type...', disabled: true },
        { value: 1, text: 'Savings' },
        { value: 2, text: 'Stocks' },
        { value: 3, text: 'Home Value' },
        { value: 4, text: 'Mutual Funds' },
        { value: 5, text: 'Retirement Accounts' },
        { value: 6, text: 'Qualified Plans' },
        { value: 7, text: 'Other Assets' }
      ],
      items: [],
      editing: null
    }
  },
  computed: {
    totalBalance: function () {
      let total = 0.0
      this.value.assets.forEach(function(item) { total += parseFloat(item.balance) })
      return total
    },
    totalMonthlyContribution: function () {
      let total = 0.0
      this.value.assets.forEach(function(item) { total += parseFloat(item.monthlyContribution) })
      return total
    }
  },
  methods: {
    add () {
      this.$bus.$emit('modal:editAsset')
    },
    edit (item) {
      let index = this.value.assets.indexOf(item)
      this.$bus.$emit('modal:editAsset', item)
    },
    remove (item) {
      let token = this.$route.params.token
      this.$api.clients.intake.deleteAsset({ token: token, id: item.id })
        .then(() => {
          this.$snotify.success('Asset Deleted Successfully')
          this.$emit('update')
        })
        .catch(() => {
          this.$snotify.error('There was a problem removing the policy.')
        })
    },
    save (value) {
      let token = this.$route.params.token
      value.clientId = this.clientId
      if (value.id) {
        this.$api.clients.intake.updateAsset({ token: token, id: value.id }, value)
          .then((response) => {
            this.$snotify.success('Asset Saved Successfully')
            this.$emit('update', value)
          })
          .catch(() => {
            this.$snotify.error('There was a problem saving the asset.')
          })
      } else {
        this.$api.clients.intake.createAsset({ token: token }, value)
          .then((response) => {
            this.$snotify.success('Asset Added Successfully')
            this.$emit('update', value)
          })
          .catch(() => {
            this.$snotify.error('There was a problem saving the asset.')
          })
      }
    }
  }
}
</script>
