<template>
  <b-row>
    <b-col sm="12">
      <h3>Credit Cards</h3>
      <p class="text-muted">List all credit cards here.</p>
      <!-- Credit Cards -->
      <b-table v-if="value.creditCards.length > 0" :small="true" hover :items="value.creditCards" :fields="fields" v-on:row-clicked="edit" foot-clone>
        <template v-slot:cell(balance)="data">
          <dollars :value="data.value"></dollars>
        </template>
        <template v-slot:cell(minimumPayment)="data">
          <dollars :value="data.value" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:cell(actualPayment)="data">
          <dollars :value="data.value" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:cell(rate)="data">
          <percent :value="data.value"></percent>
        </template>
        <template v-slot:cell(delete)="data">
          <div class="text-right">
            <b-button size="sm" variant="ghost-danger" @click.stop="remove(data.item)"><i class="fa fa-remove"></i></b-button>
          </div>
        </template>
        <template v-slot:foot(issuer)><span></span></template>
        <template v-slot:foot(type)><span></span></template>
        <template v-slot:foot(balance)>
          <dollars :value="totalBalance"></dollars>
        </template>
        <template v-slot:foot(minimumPayment)>
          <dollars :value="totalMinimumPayment" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:foot(actualPayment)>
          <dollars :value="totalActualPayment" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:foot(rate)><span></span></template>
      </b-table>
      <!-- Add Credit Card -->
      <b-button size="sm" variant="outline-success" @click="add()">
        <i class="fa fa-plus"></i>&nbsp;Add Credit Card
      </b-button>
      <credit-card-modal v-on:save="save"></credit-card-modal>
    </b-col>
  </b-row>
</template>

<script>
import CreditCardModal from '@/components/modals/CreditCardModal'
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'

export default {
  name: 'CreditCards',
  props: [ 'clientId', 'value' ],
  components: {
    CreditCardModal,
    Dollars,
    Percent
  },
  computed: {
    totalBalance: function () {
      let total = 0.0
      this.value.creditCards.forEach(function(item) { total += parseFloat(item.balance) })
      return total
    },
    totalMinimumPayment: function () {
      let total = 0.0
      this.value.creditCards.forEach(function(item) { total += parseFloat(item.minimumPayment) })
      return total
    },
    totalActualPayment: function () {
      let total = 0.0
      this.value.creditCards.forEach(function(item) { total += parseFloat(item.actualPayment) })
      return total
    }
  },
  data () {
    return {
      fields: [
        { key: 'issuer', label: 'Bank/Issuer' },
        { key: 'type', label: 'Type', formatter: (value, key, item) => item.type.name },
        { key: 'balance', class: 'numeric' },
        { key: 'minimumPayment', class: 'numeric' },
        { key: 'actualPayment', class: 'numeric' },
        { key: 'rate', class: 'numeric' },
        { key: 'delete', label: '', class: 'delete' }
      ],
      items: []
    }
  },
  methods: {
    add () {
      this.$bus.$emit('modal:editCreditCard')
    },
    edit (item) {
      let index = this.value.creditCards.indexOf(item)
      this.editing = index
      this.$bus.$emit('modal:editCreditCard', item)
    },
    remove (item) {
      let token = this.$route.params.token
      this.$api.clients.intake.deleteCreditCard({ token: token, id: item.id })
        .then(() => {
          this.$emit('update')
          this.$snotify.success('CreditCard Deleted Successfully')
        })
        .catch((err) => {
          this.$snotify.error('There was a problem removing the policy.')
        })
    },
    save (value) {
      let token = this.$route.params.token
      value.clientId = this.clientId
      if (value.id) {
        this.$api.clients.intake.updateCreditCard({ token: token, id: value.id }, value)
          .then((response) => {
            this.$emit('update', value)
            this.$snotify.success('Credit Card Saved Successfully')
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the Credit Card.')
          })
      } else {
        this.$api.clients.intake.createCreditCard({ token: token }, value)
          .then((response) => {
            this.$emit('update', value)
            this.$snotify.success('Credit Card Added Successfully')
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the Credit Card.')
          })
      }
    }
  }
}
</script>
