<template>
  <div class="app align-items-center">
    <vue-snotify></vue-snotify>
    <div class="container mt-3">
      <img class="navbar-brand-full" v-if="logo" :src="logo" width="300" height="44" alt="Inspiring Hope and Freedom">
      <b-form class="mt-3">
        <b-card>
          <div slot="header">
            <b-row>
              <b-col sm="6">
                New Client Information
              </b-col>
              <b-col sm="6" class="text-right">
                <b-badge :variant="intakeRequest.status.style"><strong>Status:</strong> {{ intakeRequest.status.name }}</b-badge>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col sm="12">
              <b-alert :variant="intakeRequest.status.style" :show="intakeRequest.status.message != null">{{ intakeRequest.status.message }}</b-alert>
            </b-col>
          </b-row>
          <b-card no-body>
            <b-tabs card pills vertical nav-wrapper-class="w-40" v-model="selectedTab">
              <b-tab active>
                <template slot="title">
                  <i class="fa fa-user"></i> Primary Contact
                </template>
                <primary-contact :clientId="client.id" v-model="$v.client.primaryContact" @update="savePrimaryContact()"></primary-contact>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-user-o"></i> Secondary Contact
                </template>
                <secondary-contact :clientId="client.id" v-model="$v.client.secondaryContact" @update="saveSecondaryContact()"></secondary-contact>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-users"></i> Other Contacts
                </template>
                <other-contacts :clientId="client.id" v-model="client" @update="getContacts"></other-contacts>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-list"></i> Existing Policies
                </template>
                <policies :clientId="client.id" v-model="client" :contacts="client.contacts" @update="getPolicies"></policies>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-money"></i> Assets
                </template>
                <assets :clientId="client.id" v-model="client" @update="getAssets"></assets>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="icon-home"></i> Mortgages
                </template>
                <mortgages :clientId="client.id" v-model="client" @update="getMortgages"></mortgages>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-automobile"></i> Vehicles
                </template>
                <!-- Vehicles -->
                <vehicles :clientId="client.id" v-model="client" @update="getVehicles"></vehicles>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-credit-card"></i> Credit Cards
                </template>
                <credit-cards :clientId="client.id" v-model="client" @update="getCreditCards"></credit-cards>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="icon-pie-chart"></i> Loans
                </template>
                <!-- Loans -->
                <loans :clientId="client.id" v-model="client" @update="getLoans"></loans>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-comment"></i> Comments
                </template>
                <comments v-model="$v.client" @update="saveComments()"></comments>
              </b-tab>
            </b-tabs>
          </b-card>
          <b-row>
            <b-col sm="12" class="text-right">
              <b-button v-if="intakeRequest.statusId == 2" variant="success" :disabled="!complete" @click="completeRegistration()">Complete Registration</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators"
import { merge } from "lodash"

import PrimaryContact from './components/PrimaryContact'
import SecondaryContact from './components/SecondaryContact'
import OtherContacts from './components/OtherContacts'
import Assets from './components/Assets'
import Mortgages from './components/Mortgages'
import CreditCards from './components/CreditCards'
import Vehicles from './components/Vehicles'
import Loans from './components/Loans'
import Policies from './components/Policies'
import Comments from './components/Comments'

export default {
  name: 'Intake',
  components: {
    PrimaryContact,
    SecondaryContact,
    OtherContacts,
    Assets,
    Mortgages,
    CreditCards,
    Vehicles,
    Loans,
    Policies,
    Comments
  },
  computed: {
    complete () {
      if (!this.$v.client.$invalid) {
        return true
      }
      return false
    },
    logo () {
      if (this.client && this.client.organizationId > 0) {
        return `//cdn.inspiringhopeandfreedom.com/organizations/${this.client.organizationId}/images/logo.png`
      } else {
        return null
      }
    }
  },
  data () {
    return {
      selectedTab: 0,
      saveStatus: {
        saving: false
      },
      intakeRequest: {
        status: {
          name: null
        }
      },
      client: {
        id: null,
        organizationId: null,
        primaryContact: {
          id: null,
          firstName: null,
          lastName: null,
          address: {
            street: null,
            city: null,
            state: null,
            provinceId: null,
            postalCode: null,
            zipcode: null,
            countryId: null,
          },
          birthday: null,
          age: null,
          homePhone: {
            number: null
          },
          mobilePhone: {
            number: null
          },
          workPhone: {
            number: null
          },
          emailAddress: {
            address: null
          },
          income: {
            monthly: null,
            additionalMonthly: null
          }
        },
        secondaryContact: {
          id: null,
          firstName: null,
          lastName: null,
          birthday: null,
          age: null,
          homePhone: {
            number: null
          },
          mobilePhone: {
            number: null
          },
          workPhone: {
            number: null
          },
          emailAddress: {
            address: null
          },
          income: {
            monthly: null
          }
        },
        contacts: [],
        comments: null,
        assets: [],
        mortgages: [],
        creditCards: [],
        vehicles: [],
        loans: [],
        existingPolicies: []
      }
    }
  },
  methods: {
    savePrimaryContact (value) {
      let token = this.$route.params.token
      this.client.primaryContact.contactTypeId = 1
      this.client.primaryContact.income.monthly = this.client.primaryContact.income.monthly || 0
      this.client.primaryContact.income.additionalMonthly = this.client.primaryContact.income.additionalMonthly || 0
      this.$api.clients.intake.updateContact({ token: token, id: this.client.primaryContact.id }, this.client.primaryContact)
        .then((response) => {
          this.$snotify.success('Primary Contact Saved')
          this.$v.client.primaryContact.$reset()
          this.getContacts()
        })
        .catch((err) => {
          this.$snotify.error('There was a problem saving.')
        })
    },
    saveSecondaryContact (value) {
      let token = this.$route.params.token
      this.client.secondaryContact.contactTypeId = 2
      this.client.secondaryContact.income.monthly = this.client.secondaryContact.income.monthly || 0
      if (this.client.secondaryContact && this.client.secondaryContact.id > 0) {
        this.$api.clients.intake.updateContact({ token: token, id: this.client.secondaryContact.id }, this.client.secondaryContact)
          .then((response) => {
            this.$snotify.success('Secondary Contact Saved')
            this.$v.client.secondaryContact.$reset()
            this.getContacts()
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving.')
          })
      } else {
        this.$api.clients.intake.createContact({ token: token }, this.client.secondaryContact)
          .then((response) => {
            this.$snotify.success('Secondary Contact Created')
            this.$v.client.secondaryContact.$reset()
            this.getContacts()
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving.')
          })
      }
    },
    saveComments (value) {
      let token = this.$route.params.token
      this.$api.clients.intake.updateClient({ token: token, id: this.client.id }, { comments: this.client.comments })
        .then((response) => {
          this.$snotify.success('Comments Saved')
          this.$v.client.comments.$reset()
          this.getIntake()
        })
        .catch((err) => {
          this.$snotify.error('There was a problem saving.')
        })
    },
    completeRegistration () {
      // Update status
      this.$api.clients.intake.complete(this.$route.params.token)
        .then((response) => {
          this.intakeRequest = response.data
          this.$snotify.success('Intake Request Submitted')
          window.scrollTo(0,0)
        })
        .catch((err) => {
          this.$snotify.error('There was a problem submitting your Intake Request.')
        })
    },
    reset () {
      this.$v.$reset()
    },
    async getIntake () {
      // Fetch Intake Client
      const { data: intakeRequest } = await this.$api.clients.intake.get(this.$route.params.token)

      let client = intakeRequest.client
      this.intakeRequest = intakeRequest
      this.client.id = client.id
      this.client.organizationId = client.organizationId

      // Comments
      this.client.comments = client.comments

      // Load Asset Types
      if (client.organization) {
        this.$store.dispatch('global/getAssetTypes', client.organization.countryId)
      }
    },
    async getAssets () {
      // Fetch Assets
      const { data: assets } = await this.$api.clients.intake.getAssets({ token: this.$route.params.token })
      this.client.assets = assets
    },
    async getContacts () {
      // Contacts
      const { data: contacts } = await this.$api.clients.intake.getContacts({ token: this.$route.params.token })
      this.client.contacts = contacts

      const primaryContacts = contacts.filter(o => o.contactTypeId == 1)
      const secondaryContacts = contacts.filter(o => o.contactTypeId == 2)

      if (primaryContacts.length > 0) {
        const primaryContact = primaryContacts[0]

        this.client.primaryContact.id = primaryContact.id
        this.client.primaryContact.firstName = primaryContact.firstName
        this.client.primaryContact.lastName = primaryContact.lastName
        Object.assign(this.client.primaryContact.address, primaryContact.address)
        this.client.primaryContact.birthday = primaryContact.birthday
        Object.assign(this.client.primaryContact.homePhone, primaryContact.homePhone)
        Object.assign(this.client.primaryContact.mobilePhone, primaryContact.mobilePhone)
        Object.assign(this.client.primaryContact.workPhone, primaryContact.workPhone)
        Object.assign(this.client.primaryContact.emailAddress, primaryContact.emailAddress)
        Object.assign(this.client.primaryContact.income, primaryContact.income)
      }

      if (secondaryContacts.length > 0) {
        const secondaryContact = secondaryContacts[0]

        this.client.secondaryContact.id = secondaryContact.id
        this.client.secondaryContact.firstName = secondaryContact.firstName
        this.client.secondaryContact.lastName = secondaryContact.lastName
        this.client.secondaryContact.birthday = secondaryContact.birthday
        Object.assign(this.client.secondaryContact.homePhone, secondaryContact.homePhone)
        Object.assign(this.client.secondaryContact.mobilePhone, secondaryContact.mobilePhone)
        Object.assign(this.client.secondaryContact.workPhone, secondaryContact.workPhone)
        Object.assign(this.client.secondaryContact.emailAddress, secondaryContact.emailAddress)
        Object.assign(this.client.secondaryContact.income, secondaryContact.income)
      }
    },
    async getCreditCards () {
      // Credit Cards
      const { data: creditCards } = await this.$api.clients.intake.getCreditCards({ token: this.$route.params.token })
      this.client.creditCards = creditCards
    },
    async getLoans() {
      // Loans
      const { data: loans } = await this.$api.clients.intake.getLoans({ token: this.$route.params.token })
      this.client.loans = loans
    },
    async getMortgages () {
      // Mortgages
      const { data: mortgages } = await this.$api.clients.intake.getMortgages({ token: this.$route.params.token })
      this.client.mortgages = mortgages
    },
    async getVehicles () {
      // Vehicles
      const { data: vehicles } = await this.$api.clients.intake.getVehicles({ token: this.$route.params.token })
      this.client.vehicles = vehicles
    },
    async getPolicies () {
      // Existing Policies
      const { data: policies } = await this.$api.clients.intake.getPolicies({ token: this.$route.params.token })
      this.client.existingPolicies = policies.filter(o => o.existing)
    }
  },
  async created () {
    await this.getIntake()
    await this.getAssets()
    await this.getContacts()
    await this.getCreditCards()
    await this.getLoans()
    await this.getMortgages()
    await this.getVehicles()
    await this.getPolicies()

    this.$nextTick(() => { this.$v.$reset() })

    // this.$api.clients.intake.get(this.$route.params.token)
    //   .then((response) => {
    //     let intakeRequest = response.data

    //     // Reset the validation
    //     this.$nextTick(() => { this.$v.$reset() })
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     if (err.response.status == 404) {
    //       this.$router.push({ name: 'Intake404', params: { token: this.$route.params.token } })
    //     }
    //   })
  },
  validations: {
    client: {
      primaryContact: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        address: {
          street: {},
          city: {},
          state: {},
          provinceId: {},
          postalCode: {},
          zipcode: {},
          countryId: {},
        },
        homePhone: {
          number: {}
        },
        mobilePhone: {
          number: {}
        },
        emailAddress: {
          address: {
            required
          }
        },
        birthday: {
          required
        },
        income: {
          monthly: {},
          additionalMonthly: {}
        }
      },
      secondaryContact: {
        firstName: {},
        lastName: {},
        birthday: {},
        emailAddress: {
          address: {}
        },
        homePhone: {
          number: {}
        },
        mobilePhone: {
          number: {}
        },
        income: {
          monthly: {}
        }
      },
      comments: {}
    }
  }
}
</script>

<style>
  .table-sm td.delete {
    width: 28px;
  }
  .table-sm td {
    vertical-align: middle;
  }
  .table-sm td.numeric, .table-sm th.numeric {
    text-align: right;
  }
</style>
