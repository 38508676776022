<template>
  <b-row>
    <b-col sm="12">
      <h3>Existing Life Insurance</h3>
      <p class="text-muted">List any existing Whole, Term or other life insurance policies you have.</p>
      <b-row>
        <b-col sm="12">
          <b-table hover :small="true" :items="value.existingPolicies" :fields="existingPolicyFields" @row-clicked="editPolicy" v-if="value.existingPolicies.length > 0">
            <template v-slot:cell(faceValue)="data">
              <dollars :value="data.value"></dollars>
            </template>
            <template v-slot:cell(annualPremium)="data">
              <dollars :value="data.value" :postfix="'yr'"></dollars>
            </template>
            <template v-slot:cell(cashValue)="data">
              <dollars :value="data.value"></dollars>
            </template>
            <template v-slot:cell(loanAmount)="data">
              <dollars :value="data.value"></dollars>
            </template>
            <template v-slot:cell(delete)="data">
              <div class="text-right">
                <b-button size="sm" variant="ghost-danger" @click.stop="removePolicy(data.item)"><i class="fa fa-remove"></i></b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-button size="sm" variant="outline-success" @click="addPolicy()">
        <i class="fa fa-plus"></i>&nbsp;Add Existing Policy
      </b-button>
      <life-insurance-modal @save="savePolicy" :contacts="contacts"></life-insurance-modal>
    </b-col>
  </b-row>
</template>
<script>
import LifeInsuranceModal from '@/components/modals/LifeInsuranceModal'
import Dollars from '@/components/text/Dollars'

export default {
  props: [ 'clientId', 'value', 'contacts' ],
  components: {
    LifeInsuranceModal,
    Dollars
  },
  data() {
    return {
      policies: [],
      existingPolicyFields: [
        { key: 'name', label: 'Name' },
        { key: 'type.name', label: 'Type' },
        { key: 'faceValue', class: 'numeric' },
        { key: 'annualPremium', class: 'numeric' },
        { key: 'cashValue', class: 'numeric' },
        { key: 'loanAmount', class: 'numeric' },
        { key: 'delete', label: '', class: 'delete' }
      ],
    }
  },
  methods: {
    addPolicy () {
      this.$bus.$emit('modal:editLifeInsurance')
    },
    editPolicy (item) {
      let index = this.policies.indexOf(item)
      this.policies.editing = index
      this.$bus.$emit('modal:editLifeInsurance', item)
    },
    removePolicy (item) {
      let token = this.$route.params.token
      this.$api.clients.intake.deletePolicy({ token: token, id: item.id })
        .then(() => {
          this.$emit('update')
          this.$snotify.success('Policy Deleted Successfully')
        })
        .catch(() => {
          this.$snotify.error('There was a problem removing the policy.')
        })

    },
    savePolicy (value) {
      let token = this.$route.params.token
      value.clientId = this.clientId
      value.existing = true
      if (value.id) {
        this.$api.clients.intake.updatePolicy({ token: token, id: value.id }, value)
          .then((response) => {
            this.$emit('update', value)
            this.$snotify.success('Policy Saved Successfully')
          })
          .catch((err) => {
            console.log(err)
            this.$snotify.error('There was a problem saving the policy.')
          })
      } else {
        this.$api.clients.intake.createPolicy({ token: token }, value)
          .then((response) => {
            this.$emit('update', value)
            this.$snotify.success('Policy Added Successfully')
          })
          .catch((err) => {
            console.log(err)
            this.$snotify.error('There was a problem saving the policy.')
          })
      }
    }
  },
  watch: {}
}
</script>
